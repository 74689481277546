import cookies from "js-cookie";
import { StatusClassNames } from "../types/StatusClassNames";
import { onTransitionEnd } from "../utils";
export default function initCookieBox() {
    var cookieBox = document.querySelector('.cookie-box');
    var button = cookieBox === null || cookieBox === void 0 ? void 0 : cookieBox.querySelector('button');
    if (!cookieBox || !button) {
        return;
    }
    button.addEventListener('click', function () {
        cookies.set('cookie_clicked', '1', { path: '/', expires: 365 });
        cookieBox.classList.add(StatusClassNames.hide);
        onTransitionEnd(cookieBox, function () { return cookieBox.remove(); });
    });
}
