import cookies from "js-cookie";
import { StatusClassNames } from "../types/StatusClassNames";
import { onTransitionEnd } from "../utils";
export default function initInfoBox() {
    var infoBox = document.querySelector('.info-box');
    var button = infoBox === null || infoBox === void 0 ? void 0 : infoBox.querySelector('button');
    if (!infoBox || !button) {
        return;
    }
    button.addEventListener('click', function () {
        var data = cookies.get('info_clicked');
        var list = new Set(data ? data.split('/') : []);
        list.add(infoBox.dataset.id);
        cookies.set('info_clicked', Array.from(list).join('/'), { path: '/', expires: 365 });
        infoBox.classList.add(StatusClassNames.hidden);
        onTransitionEnd(infoBox, function () { return infoBox.remove(); });
    });
}
