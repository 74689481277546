var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import EventBus from "../events/EventBus";
function onImageLoaded(img) {
    if (img.parentElement.nodeName === 'PICTURE') {
        img.parentElement.classList.add('img--loaded');
    }
    else {
        img.classList.add('img--loaded');
    }
}
export function loadImage(img) {
    if (img.getAttribute('loading') && img.loading !== 'eager') {
        img.loading = 'eager';
    }
}
export function refreshLazyImages(root) {
    var e_1, _a;
    try {
        for (var _b = __values((root !== null && root !== void 0 ? root : document).querySelectorAll('picture:not(.img--loaded) > img[loading], :not(picture) > img:not(.img--loaded)[loading]')), _c = _b.next(); !_c.done; _c = _b.next()) {
            var img = _c.value;
            handleLazyImage(img);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
}
export function handleLazyImage(img) {
    if (img.complete && img.naturalWidth) {
        onImageLoaded(img);
    }
    else {
        img.addEventListener('load', function () { return onImageLoaded(img); });
    }
}
export function initLazyLoad() {
    EventBus.on('products:load', function () { return refreshLazyImages(); });
    EventBus.on('modal:open', function (modal) { return refreshLazyImages(modal); });
}
