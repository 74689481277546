var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import '../vendor/sentry-lazy.js';
var environments = {
    'www.orental.ru': 'prod',
    'dev.orental.ru': 'test',
};
var filteredMessages = [
    'Timeout',
    'Method not found',
    'Syntax error in regular expression',
    'Event `ErrorEvent` captured as exception with message `Script error.`',
];
window.Sentry.onLoad(function () {
    var _a;
    window.Sentry.init({
        dsn: process.env.SENTRY_DSN,
        environment: (_a = environments[document.location.hostname]) !== null && _a !== void 0 ? _a : 'dev',
        // integrations: [
        //   Sentry.replayIntegration(),
        // ],
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1,
        denyUrls: [
            'bitrix',
            /https?:\/\/(?!((www|dev).)?orental.(ru|devel|home.polosatus.ru))/,
            // Chrome extensions
            /extensions\//i,
            /^chrome(-extension)?:\/\//i,
        ],
        beforeSend: function (event, hint) {
            var e_1, _a;
            var _b, _c, _d;
            var originalException = hint.originalException;
            var message;
            // @ts-ignore
            if (window.document.documentMode) {
                // ignore IE
                return null;
            }
            if (typeof originalException === 'string') {
                message = originalException;
            }
            // @ts-ignore
            if (typeof (originalException === null || originalException === void 0 ? void 0 : originalException.message) === 'string') {
                // @ts-ignore
                message = originalException.message;
            }
            if (!message) {
                message = (_d = (_c = (_b = event === null || event === void 0 ? void 0 : event.exception) === null || _b === void 0 ? void 0 : _b.values[0]) === null || _c === void 0 ? void 0 : _c.value) !== null && _d !== void 0 ? _d : 'Unknown';
            }
            if (filteredMessages.includes(message)) {
                return null;
            }
            if (event.exception && event.exception.values) {
                try {
                    for (var _e = __values(event.exception.values), _f = _e.next(); !_f.done; _f = _e.next()) {
                        var exception = _f.value;
                        if (exception.value.includes('window.yandex.sovetnik')) {
                            return null;
                        }
                        if (filterBitrixErrors(exception.stacktrace)) {
                            return null;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            if (isBurstProtectionActive()) {
                return null;
            }
            return event;
        }
    });
});
var lastEventTime;
var burstHits = 0;
var burstProtectionActive = false;
var MAX_BURST_EVENTS = 3;
function isBurstProtectionActive() {
    if (burstProtectionActive) {
        return true;
    }
    if (lastEventTime) {
        var now = new Date;
        if (now.getTime() - lastEventTime.getTime() < 3000) {
            burstHits++;
            if (burstHits >= MAX_BURST_EVENTS) {
                burstProtectionActive = true;
            }
        }
        else {
            lastEventTime = new Date();
            burstHits = 1;
        }
    }
    else {
        lastEventTime = new Date();
        burstHits = 1;
    }
    return false;
}
function filterBitrixErrors(stacktrace) {
    var _a;
    var re = new RegExp('bitrix|(/|^)crm/|chrome-extension');
    return !!((_a = stacktrace === null || stacktrace === void 0 ? void 0 : stacktrace.frames) === null || _a === void 0 ? void 0 : _a.find(function (_a) {
        var filename = _a.filename, module = _a.module;
        return filename && filename.match(re) || module && module.match(re);
    }));
}
